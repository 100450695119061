.MuiSelect-select {
  padding: 3.5% !important;
}
.service_info {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 1px 1px 6px #888888;
}
.booking_container {
  height: 405px;
}
.page1 {
  height: 400px; /* or whatever height you need */
  overflow: auto; /* Allows scrolling if content overflows */
  border: 1px solid #ccc; /* Optional: for visual clarity */
  width: 75%;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 1px 1px 6px #888888;
}
.buttons {
  width: 76%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-left: 12%;
}
.get_help {
  transform: translate(-254px, 39px) !important;
}
.booking-form {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 1px 1px 6px #888888;
}

.calender .react-calendar__navigation {
  font-size: x-large !important;
  margin-top: 10px !important;
  margin-bottom: 30px !important;
}

.calender .react-calendar__month-view__weekdays {
  display: flex !important;
  flex-wrap: nowrap !important;
  text-align: center !important;
  height: 35px !important;
  margin-top: 10px !important;
}

.calender abbr {
  text-decoration: none !important;
}

.calender .react-calendar__month-view__days {
  display: flex !important;
  flex-wrap: wrap !important;
  height: 395px !important;
}
/* .calender .react-calendar__month-view__days button {
  background-color: white !important;
} */

.calenderTimeSlot {
  display: flex !important;
  justify-content: space-evenly !important;
  height: 585px !important;
  overflow-y: scroll !important;
  scroll-behavior: smooth;
  scrollbar-width: none !important;
}

.calender .react-calendar__year-view__months,
.calender .react-calendar__century-view__decades,
.calender .react-calendar__decade-view__years {
  height: 230px !important;
}

.calender .react-calendar__navigation__prev2-button,
.calender .react-calendar__navigation__next2-button {
  display: none !important;
}

.calender .react-calendar__navigation__prev-button,
.calender .react-calendar__navigation__next-button {
  color: #0f1ef5 !important;
  border-radius: 50% !important;
  width: 8% !important;
  background-color: #caddf7 !important;
  font-weight: 700 !important;
}

.calender .react-calendar__navigation__label {
  flex-grow: 0 !important;
  background-color: #ffffff !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  pointer-events: none; /* Prevents any interactions */
  cursor: not-allowed; /* Changes the cursor to indicate it's disabled */
  width: 300px !important;
}
/* .calendar-container {
  height: 400px; 
  overflow: hidden;
} */
.calender
  .react-calendar__tile
  .react-calendar__month-view__days__day
  .react-calendar__month-view__days__day--neighboringMonth {
  border-radius: 50% !important;
  font-weight: 500 !important;
  background-color: white !important;
}
.calender
  .react-calendar__tile
  .react-calendar__month-view__days__day
  .react-calendar__month-view__days__day--neighboringMonth
  .selected {
  border-radius: 50% !important;
  font-weight: 500 !important;
  background-color: #0f1ef5 !important;
}
.timeslots {
  display: flex !important;
  flex-direction: column !important;
  gap: 10px !important;
}
.buttons-group {
  height: 75px !important;
  width: 250px !important;
  border: 3px solid #7fb4ff !important;
  border-radius: 10px !important;
  color: #0069ff !important;
  font-weight: 700 !important;
  transition: width 0.3s ease; /* Transition for width change */
}

.buttons-group:hover {
  border: 5px solid #7678f1 !important;
  background-color: white !important;
}

.buttons-group.selected {
  background-color: #666666 !important;
  color: white !important;
  /* width: 125px !important; */
}
.buttons-group.next {
  background-color: #3c39eb !important;
  color: white !important;
  width: 0; /* Start with width 0 */
  overflow: hidden; /* Hide overflow */
  transition: width 0.3s ease; /* Transition for width */
  animation: slideIn 0.5s ease forwards;
}
@keyframes slideIn {
  from {
    transform: translateX(10%);
  }
  to {
    transform: translateX(0);
  }
}

.buttons-group.next.show {
  width: 125px !important;
}
.calender .react-calendar__tile {
  background-color: white;
  border-radius: 50%;
  color: black;
}
.calender .react-calendar__tile--active {
  background: rgb(
    75,
    75,
    231
  ) !important; /* Background color for selected date */
  border-radius: 50%; /* Makes the background rounded */
  color: white !important; /* Text color for better contrast */
}

/* Optional: Focus styles */
/* .calender .react-calendar__tile--active:focus {
  outline: none;
  box-shadow: 0 0 5px darkblue; 
} */
.calendly .calendly-inline-widget {
  height: 578px !important;
}
.calendly-inline-widget {
  height: 578px !important;
}
._cUP1np9gMvFQrcPftuf .xahN8AEzyAvQtVj17TPv {
  margin: 0 !important;
}
.VJL48qbQzWENTFAh1Knk div {
  display: none !important;
}
.highlight {
  background-color: #caddf7 !important;
  color: #2463eb !important;
  font-weight: bold !important;
}

.availableDate {
  width: 50%;
}

.availableTime {
  width: 25%;
  margin-top: 40px;
}

@media screen and (max-width: 768px) {
  .page1 {
    height: 580px !important;
  }
  .booking_container {
    height: 590px !important;
  }
  .get_help_dropdown {
    transform: translate3d(-99px, 42px, 0px);
  }
  .get_help_dropdown li {
    font-size: small !important;
  }
  .availableDate {
    width: 100% !important;
  }
  .availableTime {
    width: 100% !important;
    margin-top: 10px !important;
  }
  .availableTime h4 {
    text-wrap: wrap !important;
  }
  .buttons-group {
    width: 200px !important;
  }
  .calender .react-calendar__month-view__days {
    height: 200px !important;
  }
  .calender .react-calendar__navigation {
    font-size: large !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .calender .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button {
    width: 19% !important;
  }
  .paypal .paypal-buttons {
    margin-left: -35% !important;
  }
}

.gurdwara_checkbox .form-check-input:checked {
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
}
