.my-item-container {
  background-color: #ffffff;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 10px;
  border-radius: 15px;
  box-shadow: 1px 1px 6px #888888;
}
.my-item-container .my-item-card {
  background-color: #f4f1f1;
  width: 90%;
  flex-direction: column;
  /* padding: 10px; */
  margin: 20px 0px;
  border-radius: 2px;
  box-shadow: 0px 0px 10px #f5f5f5;
  border: 1px solid #dadada;
}
.my-item-container .my-item-card:hover {
  background-color: #ffffff;
  box-shadow: 1px 1px 6px #d1d1d1;
}
.item-menu {
  cursor: pointer;
  position: absolute;
  top: 40%;
  right: 0;
  background: #fff;
  border: 1px solid #454545;
  padding: 10px;
  border-radius: 5px;
  z-index: 99;
  min-width: 150px; /* Minimum width to ensure the menu looks good */
}
.qty-status {
  padding: 5px;
  border-radius: 4px;
}
.in-stock {
  background-color: #ace7af;
}
.out-stock {
  background-color: #f5a9a9;
}
/* Responsive adjustments */
@media (max-width: 768px) {
  .item-menu {
    top: 50%;
    right: 10px;
  }
}
.light-bulb {
  cursor: pointer;
}
