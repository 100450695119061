.service-menu {
  cursor: pointer;
  position: absolute;
  top: 40%;
  right: 0;
  background: #fff;
  border: 1px solid #454545;
  padding: 10px;
  border-radius: 5px;
  z-index: 99;
  min-width: 150px; /* Minimum width to ensure the menu looks good */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .service-menu {
    top: 50%;
    right: 10px;
  }
}

