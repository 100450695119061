/* Recommended.css */
.recommended-row {
  /* padding-left: 4%; */
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: thin; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.recommended-row::-webkit-scrollbar {
  height: 8px;
}

.recommended-row::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.recommended-col {
  flex: 0 0 auto;
  margin-right: 15px;
}

.recommended-card {
  cursor: pointer;
  max-height: 230px;
  border: none;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.recommended-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.recommended-card-img {
  object-fit: cover;
  height: 160px;
  width: 100%;
}

.recommended-card-body {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  overflow: hidden;
}

.recommended-card-category {
  font-size: small;
  color: #888;
  margin: 0;
}

.recommended-card-title {
  color: black;
  font-size: small;
  /* font-weight: bold; */
  margin: 0;
}

@media (min-width: 576px) {
  .recommended-col {
    width: 30%;
  }
}

@media (min-width: 768px) {
  .recommended-col {
    width: 22%;
  }
}

@media (min-width: 992px) {
  .recommended-col {
    width: 18%;
  }
}

@media (min-width: 1200px) {
  .recommended-col {
    width: 15%;
  }
}
