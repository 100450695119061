.general__card {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 10px;
}
.general__box {
  overflow: hidden;
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.general__box .product-card {
  /* height: auto; */
  overflow: hidden;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  padding: 8px 16px;
  margin: 0 5px;
  cursor: pointer;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 4px;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

@media only screen and (max-width: 768px) {
  .general__card {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 576px) {
  .general__card {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 480px) {
  .general__card {
    grid-template-columns: 1fr;
  }
}

@media only screen and (min-width: 1200px) {
  .general__card {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media only screen and (min-width: 1600px) {
  .general__card {
    grid-template-columns: repeat(8, 1fr);
  }
}
@media only screen and (min-width: 2000px) {
  .general__card {
    grid-template-columns: repeat(10, 1fr);
  }
}
