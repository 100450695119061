/* GoogleCalendar.css */
/* .google-calendar {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  } */

h2 {
  text-align: center;
}

.calendar-container {
  display: flex;
  justify-content: space-evenly;
}

.date-picker {
  width: 50%;
  /* background-color: darkcyan; */
}

.hour-slots {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 50%; */
  /* height: 150px; */
  text-align: left;
  overflow-y: auto;
  gap: 10px;
}
.time-slot {
  /* border: 1px solid #eae5e5; */
  /* border-radius: 10px; */
  /* background-color: #04ff00; */
  /* width: 50%; */
  height: 250px;
  overflow-y: auto;
  scrollbar-width: none;
}
.cal-modal {
  background-color: #04ff00;
}
.hour-slot {
  flex: 0 0 calc(33.33% - 5px);
  padding: 15px;
  /* text-align: center; */
  cursor: pointer;
  /* border-radius: 5px; */
  transition: background-color 0.3s;
  width: 50% !important;
  /* height: 20px; */
  text-align: center;
  height: 75px !important;
  width: 200px !important;
  border: 3px solid #7fb4ff !important;
  border-radius: 10px !important;
  color: #0069ff !important;
  font-weight: 700 !important;
  transition: width 0.3s ease; /* Transition for width change */
}
.completed-date {
  color: #d2d2d2;
}
.hour-slot:hover {
  background-color: #efefef;
}

.hour-slot.selected {
  background-color: #666666 !important;
  color: white !important;
}
.hour-slot.blocked {
  background-color: #d4d4d4;
  color: #000000;
}
.selected-date {
  background-color: #2c6fff; /* Change to desired highlight color */
  color: #080808; /* Change to desired text color */
}
.available {
  background-color: #f6f9fc;
  color: #000000;
}
button {
  background-color: #f6f9fc;
  color: #000000;
  padding: 10px;
  border: none;
  /* border-radius: 5px; */
  cursor: pointer;
  /* width: auto; */
  margin-top: 10px;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.react-calendar__navigation {
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: none;
}

button:hover {
  background-color: #dfe0e1;
}

/* HourlyEventCalendar.css */
.hourly-event-calendar {
  background-color: chocolate;
  max-width: 800px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.modal-component {
  text-align: center;
  margin-top: 50px;
  /* background-color: #04ff00; */
}

button {
  padding: 1px;
  font-size: 16px;
  cursor: pointer;
  /* background-color: rgb(255, 255, 0); */
  border-radius: 5px;
}

.rbc-toolbar-label {
  display: flex;
  align-items: center;
  justify-content: end;
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.rbc-day-bg {
  background-color: #f0f0f0;
  height: fit-content !important;
}

.rotate-icon {
  cursor: pointer;
  transition: transform 0.5s ease-in-out;
}

.rotate {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 600px) {
  .calendar-container {
    display: block !important;
  }
  .date-picker,
  .time-slot {
    width: 100%;
    padding: 5px 0px;
  }
}
