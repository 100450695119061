@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

.inter-font {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

.request-container {
  overflow-y: scroll;
  /* padding: 5rem; */
  overflow-x: hidden;
}
.request-form {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 1px 1px 6px #e0dede;
  /* margin-top: 50px; */
}
.custom-checkbox {
  transform: scale(1.5); /* Adjust the scale factor as needed */
  margin-inline-end: 10px;
}

.request-form h5 {
  font-weight: bold;
}
.image-box {
  display: flex;
  /* flex-wrap: wrap; */
  width: 80px;
  height: 80px;
  /* padding: px; */
  /* margin: 10px; */
  /* flex-direction: row; */
  justify-content: center;
  border: 1px solid rgb(235, 231, 231);
  /* background-color: aqua; */
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
}

.wc_post-add-btn {
  width: 30%;
  text-align: center;
  margin: 0 auto;
  display: block;
}

@media (max-width: 1200px) {
  /* Large Tablets and Small Desktops */
  .wc_post-add-btn {
    width: 40%; /* Slightly larger width for better balance */
  }
  .wc_create-request label {
    font-size: 14px; /* Moderate font size */
  }
}
@media (max-width: 1024px) {
  /* Tablet */
  .wc_post-add-btn {
    width: 50%;
  }
  .wc_create-request label {
    font-size: 15px;
  }
}
@media (max-width: 768px) {
  /* Tablet */
  .wc_post-add-btn {
    width: 50%;
  }
  .wc_create-request label {
    font-size: 15px;
  }
}

@media (max-width: 600px) {
  /* Mobile */
  .wc_post-add-btn {
    width: 100%;
  }
  .wc_create-request label {
    font-size: 11px;
  }
}
@media (max-width: 320px) {
  /* Very Small Mobile */
  .wc_post-add-btn {
    width: 100%;
    font-size: 15px; /* Adjust button font size for better appearance */
    padding: 10px; /* Adjust padding for better button height */
  }
  .wc_create-request label {
    font-size: 10px; /* Smaller font size for better spacing */
  }
}
