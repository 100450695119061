.thank-you-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: none;
  border-radius: 10px;
  outline: none;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  padding: 30px;
  width: 400px;
  max-width: 90%;
}

.thank-you-modal .modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.thank-you-modal .thank-icon-container {
  margin-bottom: 20px;
}

.thank-you-modal .icon {
  font-size: 4rem;
  color: #28a745;
}
.thank-you-modal .image {
  width: 200px;
}

.thank-you-modal .title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.thank-you-modal .message {
  margin-bottom: 20px;
}

.thank-you-modal .close-button {
  margin-top: 20px;
}

.order-card-container {
  padding: 20px;
}

.order-list {
  list-style-type: none;
  padding: 0;
}

.order-item {
  border: 1px solid #ddd;
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.order-item:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.order-content {
  display: flex;
  align-items: center;
}

.profile-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.order-details {
  flex-grow: 1;
  padding: 20px;
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-name {
  margin: 0;
  font-size: 20px;
}

.order-status {
  margin: 0;
  font-size: 16px;
  text-transform: uppercase;
}

.order-status.pending {
  color: #f39c12;
}

.order-status.completed {
  color: #27ae60;
}

.order-info p {
  margin: 5px 0;
  font-size: 16px;
}
.badge-warning {
  background-color: #f0ad4e;
  color: #fff;
}

.badge-success {
  background-color: #5cb85c;
  color: #fff;
}

.badge-danger {
  background-color: #d9534f;
  color: #fff;
}
.cart-item-actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
