.wc_egcpl-ad {
  border-radius: 5px;
}

/* .card {
  border: none;
} */

.lca-card-body {
  cursor: pointer !important;
  text-align: left !important;
  font-size: large !important;
}
.card-title {
  color: #007bff;
}

/* .btn {
  border-radius: 5px;
} */

/* .btn-primary {
  background-color: #007bff;
  border-color: #007bff;
} */

/* .btn-success {
  background-color: #28a745;
  border-color: #28a745;
} */

/* .btn-warning {
  background-color: #ffc107;
  border-color: #ffc107;
} */

/* .btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
} */

.text-primary {
  color: #0d00ff !important;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .wc_egcpl-ad {
    height: 200px !important;
    width: 170px !important;
  }
  .lca-card-body {
    font-size: 14px !important;
    padding: 5px 15px !important;
  }
  .card-title {
    font-size: 16px !important;
  }
  .card-text {
    font-size: 14px !important;
  }
  .post-date {
    font-size: 10px !important;
  }
  .engagedCouple .btn {
    font-size: 10.9px !important;
    text-wrap: nowrap;
  }
  .need .btn {
    font-size: 10.9px !important;
    text-wrap: nowrap;
  }
  .posting {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 1024px) {
  .card-text {
    margin-left: 10px;
  }
}

@keyframes flashingRed {
  0% {
    box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px;
  }
  50% {
    /* background-color: rgba(230, 46, 46, 0.877); */
    box-shadow: rgb(250 12 12) 0px 1px 15px 1px;
  }
  100% {
    box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px;
  }
}
