.quote-card {
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  cursor: pointer;
}

.quote-label {
  color: #6c757d;
  margin-bottom: 0.25rem;
}

.quote-card p {
  margin: 0;
}

.quote-card b {
  font-size: 15px;
}

/* .quote-card .btn {
  font-size: 0.875rem;
  padding: 0.5rem 0;
} */
