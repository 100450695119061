/* Bwv.css */

.reviews-wrapper {
  padding-bottom: 1rem;
}

.reviews-container {
  display: flex;
  /* flex-wrap: wrap; */
  overflow-x: scroll;

  /* gap: 1rem; */
  /* justify-content: center; */
}
.reviews-container::-webkit-scrollbar {
  height: 1px;
}
.bwv-image {
  height: 260px;
  margin-right: 5px;
  border-radius: 3px;
  object-fit: cover;
  max-width: 337px;
  min-width: 337px;
  cursor: pointer;
}

/* Tablet (768px - 1024px): Adjust width dynamically */
@media (max-width: 1024px) {
  .bwv-image {
    max-width: 50%; /* Adjusts width for tablets */
    min-width: unset;
  }
}

/* Mobile (Below 768px): Full width */
@media (max-width: 768px) {
  .bwv-image {
    width: 100%;
    max-width: unset;
    min-width: unset;
    margin-right: 0;
  }
}

.review-card {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  width: 100%;
  max-width: 300px;
  margin: 10px;
  transition: transform 0.2s;
}

.review-card:hover {
  transform: translateY(-5px);
}

.review-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.review-profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.review-username {
  font-weight: bold;
  font-size: 1rem;
  color: #333;
}

.review-message {
  font-size: 1rem;
  color: #333;
  margin-top: 0.5rem;
}

.review-video {
  margin-top: 1rem;
}

.show-more-btn {
  display: block;
  margin: 2rem auto;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.show-more-btn:hover {
  background-color: #0056b3;
}

.review {
  flex: 0 0 auto;
  padding: 0 10px;
}
.bwv-coupan {
  padding: 20px;
  border: 1px solid #e5e1e1;
  border-radius: 10px;
  margin-bottom: 5px;
  display: "flex";
  justify-content: center;
  align-items: center;
  margin: 20px 10px;
  padding: 0px;
  background: linear-gradient(
    to right,
    #f4dada,
    #def5f9,
    #ddf9dd,
    #faf6d7,
    #f9ecd8,
    #e9e0fe,
    #fddffd,
    #dbfce4,
    #ffe1e1,
    #dadbf3
  );
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.bio-container {
  padding: 10px;
  border: 1px solid #e5e1e1;
  border-radius: 10px;
  margin-bottom: 5px;
}
.wc-scroll-tabs {
  display: flex; /* Set the container as a flexbox */
  /* gap: 5px;  */
  /* padding: 5px; */
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent the tabs from wrapping */
  scroll-behavior: smooth; /* Smooth scrolling effect */
}
.wc-scroll-tabs::-webkit-scrollbar {
  height: 1px;
}

.wc-scroll-tab {
  font-size: medium;
  cursor: pointer;
  transition: color 0.2s;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); /* Optional shadow for effect */
  padding: 10px 15px; /* Add some padding for a better click area */
}

.wc-scroll-tab:hover {
  text-shadow: 1px 1px 2px rgb(64, 221, 218); /* Text shadow effect */
}

.virtualDropdown.dropdown-menu {
  min-width: 430px !important;
  margin-left: -100px !important;
}

.virtual_col {
  border-right: 2px solid black;
}
.bwv-image-container {
  display: flex;
  overflow-x: auto;
}
.bwv-image-container::-webkit-scrollbar {
  height: 3px;
}
.bwv-videos-container {
  display: flex;
  overflow-x: auto;
  gap: 10px;
}
.bwv-videos-container::-webkit-scrollbar {
  height: 3px;
}


.packages_calender .react-calendar__tile {
  height: 50px !important;
}

.packages_calender .react-calendar__navigation {
  height: 50px !important;
}

@media screen and (max-width: 768px) {
  /* .wc-scroll-tabs {
    gap: 10px; 
  } */
  .wc-scroll-tab {
    font-size: small;
  }
  .virtualDropdown.dropdown-menu {
    min-width: 100px !important;
    margin-left: 90px !important;
    transform: none !important;
    top: 50px !important;
    left: -80px !important;
  }

  .virtual_col {
    gap: 20px;
    border-right: none;
  }
}
