.people-profile-container {
  margin: 20px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}
.people-profile-img {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.people-serive {
  /* display: flex;
  min-height: 100px;
  max-height: 187px;
  overflow-y: scroll;
  overflow-x: hidden; */
}
.people-need {
  /* min-height: 120px;
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden; */
}
.people-user-card {
  height: 136px !important;
}
