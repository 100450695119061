.profile-sidebar {
  background-color: #fff;
}

.close-icon {
  cursor: pointer;
}

.list-group-item {
  cursor: pointer;
  padding: 12px 15px;
  font-size: 16px;
  transition: background-color 0.2s;
  border-radius: 5px;
}

.list-group-item:hover {
  background-color: #f8f9fa;
}

.btn-outline-success {
  text-align: center;
}
.my-templates {
  display: flex;
  flex-direction: column;
  gap: 6px; /* Increased spacing between cards for better readability */
  max-height: 50vh;
  overflow-y: auto;
}
.my-templates::-webkit-scrollbar {
  width: 2px; /* Width of the scrollbar */
}

.inbox-template-card {
  display: flex;
  flex-direction: column; /* Ensures content stacks vertically */
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid #ddd; /* Subtle border for modern look */
  border-radius: 12px; /* Smoother rounded corners */
  background-color: #fff; /* White background for contrast */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Softer shadow for depth */
  cursor: pointer; /* Interactive feel */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 10px; /* Ample padding for content */
}

.inbox-template-card:hover {
  transform: translateY(-5px); /* Noticeable lift on hover */
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.my-temp-card {
  display: flex;
  flex-direction: column;
  gap: 8px; /* Space between title and description */
}

.my-temp-card strong {
  font-size: 12px; /* Larger title font size for emphasis */
  color: #333; /* Darker color for better readability */
}

.my-temp-card span {
  font-size: 0.7rem; /* Smaller font size for description */
  color: #666; /* Muted color for secondary information */
  line-height: 1.4; /* Improves readability */
}
