.fa-question {
  border: 1px solid black;
  border-radius: 50%;
  padding: 2px;
  background-color: black;
  font-size: 12px;
  color: #fff;
}
.modal-content {
  border-radius: "1.5rem";
}
.appointment-gift {
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  /* Responsive styles */
  @media (max-width: 768px) {
    padding: 15px;
  }
  @media (max-width: 480px) {
    padding: 10px;
  }
}

.appointment-gift .row {
  align-items: center;
  margin-bottom: 10px;
}

.appointment-gift .col-auto {
  padding: 0;
}

.appointment-gift input[type="radio"] {
  margin: 0 10px 0 0;
  cursor: pointer;
}

.appointment-gift .text-primary {
  font-weight: bold;
  font-size: 16px;
}

.appointment-gift h6 {
  margin-top: 10px;
  font-size: 18px;
}

.appointment-gift small {
  font-size: 14px;
  color: #666;
}
/* index.css */
#zmmtg-root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.start-zoom {
  background-color: #f2ff00;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.start-zoom-text {
  color: #000;
}
