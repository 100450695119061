.no-templates {
  background-color: #ffffff;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 50px;
  margin: 30px 0px;
  border-radius: 15px;
  box-shadow: 1px 1px 6px #888888;
}
.no-templates .card .img {
  height: 100px;
  width: 150px;
  object-fit: cover;
  border-radius: 8px;
}
.pending-templates {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 cards per row for desktop */
  gap: 20px;
  justify-content: center;
}

@media (max-width: 1024px) {
  /* Tablet view */
  .pending-templates {
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
  }
}

@media (max-width: 768px) {
  /* Mobile view */
  .pending-templates {
    grid-template-columns: 1fr; /* 1 card per row */
  }
}

.template-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  width: 100%; /* Take full width of the grid cell */
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.template-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.template-name {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.template-description {
  font-size: 14px;
  color: #757575;
  margin: 5px 0 0;
}

.template-actions {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.my-template-btn {
  padding: 5px 10px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.menu-icon-template {
  font-size: 24px;
  color: #757575;
  cursor: pointer;
}
