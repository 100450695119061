/* General Styles */
.image-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  cursor: pointer;
  margin: 5px auto;
  overflow: hidden;
  text-align: center;
  transition: all 0.4s ease;
}

/* Initial State */
.image-box-initial {
  width: 100px;
  height: 100px;
  border: 2px dashed #aaa;
  background-color: #f5f5f5;
  box-shadow: none;
}

/* Uploaded State */
.image-box-uploaded {
  width: 350px;
  height: 180px;
  border: none;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Image Preview */
.image-preview .uploaded-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

/* Upload Icon */
.upload-icon {
  font-size: 30px;
  color: #aaa;
}
