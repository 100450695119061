/* Hero area Section  */
.hero__area {
  width: 100%;
  height: auto;
  background: #f6f9fc;
  box-shadow: inset 0px 16px 15px -3px rgba(0, 0, 0, 0.1);
}
/* categories wise featureSection */
.cate_wise_feature {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  margin: 20px 0px;
}
.cate_feature {
  width: 100%;
  /* height: 250px; */
  padding: 15px;
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  /* box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15); */
}
.cate_feature img {
  width: 100%;
  height: 220px;
  /* object-fit: contain; */
}
/* Service area Section */
.service__item {
  padding: 20px;
  display: flex;
  align-items: center;
  column-gap: 0.7rem;
  /* border-radius: 5px; */
  cursor: pointer;
  margin-bottom: 20px;
}
.service__item span i {
  font-size: 2.2rem;
  background: var(--color-C22026);
  padding: 10px;
  border-radius: 50px;
  color: var(--secondary-color);
  font-weight: 400 !important;
}
.service__item h3 {
  color: var(--primary-color);
  font-size: 1.1rem;
  font-weight: 600;
}

.service__item p {
  font-size: 0.9rem;
  color: #222;
  margin-top: 5px;
}

/* Trending Products Section */
.section__title {
  border-bottom: 2px solid #c2202669;
  padding-bottom: 6px !important;
  padding-top: 1rem;
}
.btmborder__st {
  align-items: center;
  border-bottom: 1px solid #e2e5ec !important;
}

.timer__count {
  background: var(--primary-color);
  height: auto;
}

.timer__count img {
  width: 70% !important;
  height: 70% !important;
  object-fit: contain;
}

.store__btn {
  background: var(--secondary-color);
  color: var(--primary-color);
}

.store__btn a:hover {
  color: var(--primary-color);
}

.homeSlide ul {
  position: absolute;
  bottom: 20px;
}

.homeSlide ul li {
  border: 2px solid #b8b7b7;
  border-radius: 50%;
  padding: 10px;
}

.homeSlide ul li button {
  background: transparent !important;
  color: transparent !important;
}

.homeSlide ul li button::before {
  font-size: 15px !important;
  color: var(--color-C22026) !important;
  position: absolute;
  top: 2px;
  left: 0;
  margin: auto;
}

.productlistCard {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  background: #ffffff !important;
  margin-top: 25px;
}

.section__title h3 {
  font-weight: 500;
  font-size: 1.2rem;
  border-width: 2px !important;
  color: #383737c7;
}

/* Add Banner Section */
.addbanner_card {
  padding: 15px;
  margin-top: 25px;
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  /* box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15); */
}
@media only screen and (max-width: 1024px) {
  .hero-section img {
    height: 100%;
    object-fit: cover;
  }
}
@media only screen and (max-width: 992px) {
  .hero__content h2 {
    font-size: 2rem;
  }
  .section__title {
    font-size: 1.7rem;
  }
  .counter__img {
    display: none;
  }
  .clock__top-content {
    text-align: center;
  }
  .clock__top__content h4 {
    font-size: 0.9rem !important;
  }
  .clock__top__content h3 {
    font-size: 1rem !important;
  }
  .count__down-col {
    text-align: center;
  }
}
.paddingsst {
  padding: 0px;
}
.loadcategory {
  width: 100%;
  height: 494px !important;
}
.loadslidertop {
  width: 100%;
  height: 400px !important;
}
.tgl-hero {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40;
  margin-bottom: 30px;
}
@media only screen and (max-width: 768px) {
  .hero__content h2 {
    font-size: 1.6rem;
  }
  .hero__content p {
    font-size: 0.9rem;
  }
  .buy__btn a {
    font-size: 0.9rem;
  }
  .section__title {
    font-size: 1.4rem;
  }
  .cate_wise_feature {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;
  }
  .hero-section img {
    height: 100%;
    object-fit: cover;
  }
}

@media only screen and (max-width: 576px) {
  .hero__content {
    padding-top: 0;
    margin-bottom: 40px;
  }
  .cate_wise_feature {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;
  }
  .productlistCard {
    margin-top: 0px;
  }
  .hero-section img {
    height: 100%;
    object-fit: cover;
  }
  .hero-hd-text {
    font-size: 25px;
    line-height: 43px !important;
  }
  .vendors-sec {
    width: 100% !important;
    text-align: center;
  }
}
.vendors-sec {
  width: 40%;
}

@media only screen and (max-width: 992px) {
  .service__item {
    padding: 10px;
  }
  .service__item span i {
    font-size: 1.8rem;
    padding: 5px;
  }
  .service__item h3 {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 768px) {
  .service__item {
    margin-bottom: 1.5rem;
  }
  .service__item span i {
    font-size: 1.5rem;
    padding: 5px;
  }
}

@media only screen and (max-width: 576px) {
  .service__item {
    padding: 20px;
  }
}

/* new styles for new home page */

.hero-section {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  overflow: hidden;
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  z-index: 2;
  width: 100%;
}

.hero-content h1,
.hero-content p,
.hero-content span {
  margin: 0;
  text-shadow: 0px 0px 10px #0000009e;
}

.hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(
    0,
    0,
    0,
    0.2
  ); /* Adjust the darkness by changing the alpha value */
  z-index: 2;
}
.icon-container {
  /* position: absolute; */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  /* bottom: -110px; */
  align-items: center;
  /* z-index: 9; */
}
.icon-container div {
  margin: 0 15px;
  text-align: center;
}
/* .main-categories {
  background-color: aqua;
  display: flex;
} */

.icons {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: rgb(255, 213, 0);
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.icons:hover {
  background-color: yellow;
}

.icon-container div svg {
  font-size: 24px;
  color: black;
}

/* .sections-content {
  margin: 50px;
  width: 90%;
  overflow: hidden;
} */
/* .connect-vendors {
  overflow: hidden;
  position: relative;
  width: 100%;
} */
.sections-content hr {
  border-width: 3px;
  width: 100%;
  border-color: rgb(255, 225, 0);
}
.browse-by-category {
  display: grid;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.category-list {
  padding: 10px;
  background-color: #e2e5ec;
  margin: 2px;
  border-radius: 10px;
}
/* .category-item {
  width: 100%;
  height: 100%;
  background-color: #dbdbdb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  transition: box-shadow 0.3s ease; 
} */
.category-item img {
  width: 100% !important;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
}
.category-item p {
  font-size: 18px !important;
  padding-top: 10px !important;
}
.category-item:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add box shadow on hover */
  background-color: #ffffff;
}
.type-selector {
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  transition: color 0.3s ease;
}

.type-selector:hover {
  text-decoration: underline;
}

.subtype-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subtype-container > div {
  transition: transform 0.3s ease; /* Add a transition for the transform property */
}

.subtype-container > div:hover {
  transform: scale(1.05); /* Scale up the subtype container on hover */
}
.dark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000052;
}
.connect-vendors {
  background: linear-gradient(0deg, rgb(0 0 0 / 31%), rgb(0 0 0 / 39%)),
    url(https://mcmscache.epapr.in/post_images/website_350/post_25610392/full.jpeg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .type-selector {
    font-size: 19px !important;
  }
  .wc_search-result {
    left: 10px !important;
    width: 96% !important;
  }
  .dark-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000052;
  }
  .hero-section {
    position: relative;
    width: 100%;
    height: 45vh;
    overflow-y: scroll;
    overflow: hidden;
  }
  .hero-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    z-index: 2;
    width: 100%;
  }

  .hero-content h1,
  .hero-content p,
  .hero-content span {
    margin: 0;
    text-shadow: 0px 0px 10px #0000009e;
  }
  .tgl-hero {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40;
    margin-bottom: 10px;
  }
}
