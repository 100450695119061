.wc-people-community {
  padding: 20px 40px;
  background-color: #fff;
}
.input-container {
  position: relative;
  width: 100%;
}
.users-list-box {
  background-color: #f4f4f4;
  padding: 5px;
  border-radius: 5px;
}
.search-results-container {
  position: absolute; /* Position search results absolutely within the input container */
  width: 30%; /* Makes sure the search results take full width of the input */
  z-index: 10; /* Ensures it overlaps above other content like UsersContainer */
  background-color: white; /* Background color to make the search results visible */
  border: 1px solid #ddd; /* Optional: Adds a border around the search results */
  max-height: 80vh; /* Optional: Adds a max height for scrolling */
  overflow-y: auto; /* Optional: Allows scrolling if the search results exceed max height */
}

.search-results-container::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
}

.search-input {
  padding-right: 30px; /* Space for the clear button */
  width: 100%;
}

.clear-button {
  position: absolute;
  right: 10px; /* Position the X inside the input */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust for vertical centering */
  cursor: pointer;
  color: gray; /* Change color if desired */
}

.list-group-item {
  cursor: pointer;
  background-color: #f1f1f1;
}

.people-user-card {
  background-color: #fff; /* Background color */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Shadow effect */
  border-radius: 10px; /* Rounded corners */
  transition: transform 0.3s; /* Smooth hover effect */
}

.people-user-card:hover {
  transform: scale(1.02); /* Slightly enlarge on hover */
}

.people-user-card img {
  border: 2px solid #f0f0f0; /* Optional border around image */
}

@media (max-width: 767px) {
  .search-results-container {
    width: 80%;
  }
}
