.login-main {
  background-color: #e7e2ff;
}
.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.password-input {
  padding-right: 40px; /* Leave space for the eye icon */
}

.eye-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: #666;
}

.login-wrapper {
  border-radius: 30px;
  box-shadow: 0px 0px 20px #dadada;
  background: #fff;
}

/* .auth__form {

} */
.login-img {
  width: 100% !important;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 30px 0px 0px 30px;
}

.form__group > .form-control {
  width: 100%;
  color: #000 !important;
  background: #f3f1ff;
  border-radius: 30px !important;
  border: 1px solid #0000003b !important;
  padding: 22px;
}

.inst_socl-login {
  text-align: center;
  width: 89px;
  background: #fff;
  margin-top: -30px;
  z-index: 9;
}

.auth__btn {
  background: #0d6efd !important;
  color: #fff !important;
  padding: 7px 30px;
  border-radius: 30px;
}

.auth__btn:hover {
  background: none !important;
  color: #0d6efd !important;
  border-color: #0d6efd;
}

.form__group input[type="file"] {
  cursor: pointer;
  color: var(--secondary-color);
  margin-right: 190px !important;
  border-radius: 5px !important;
}

@media only screen and (max-width: 576px) {
  .auth__form {
    width: 50%;
    margin: 0 auto;
    padding: 18px;
    border-radius: 0px;
    box-shadow: 0px 0px 1px 0px;
  }

  .login-feture {
    flex-direction: column-reverse;
  }

  .fb-styles {
    width: 50%;
    margin: auto;
  }
  .fb-styles-signin {
    width: 80%;
  }
}

@media only screen and (max-width: 992px) {
  .auth__form {
    width: 90%;
    margin: 20 auto !important;
    /* padding: 40px; */
    border-radius: 0px;
    box-shadow: 0px 0px 1px 0px;
  }

  .login-img,
  .signin-image {
    border-radius: 0px !important;
  }
}

.fb-styles {
  border-radius: 4px;
  background: #375397;
  color: #fff;
  padding: 0px 0px 7px 8px;
  margin-top: 3px;
}

.fb-styles svg {
  font-size: 24px;
}

.fb-styles button {
  background: none;
  color: #fff;
  font-size: 14px;
}

.signin-image {
  width: 100% !important;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 30px 0px 0px 30px;
}

.signin-wrapper {
  border-radius: 30px;
  box-shadow: 0px 0px 20px #dadada;
  background: #fff;
}

.wd-signup {
  text-align: center;
  width: 89px;
  background: #fff;
  margin: -30px auto auto auto;
  z-index: 9;
}
.fb-styles button {
  font-size: 12px;
}
.login-ctr-select .css-1d6ozc4-control,
.signup-ctr-select .css-1d6ozc4-control {
  border-radius: 100px;
}
.auth__btn:hover {
  border: 1px solid #0d6efd;
}
