.user-info-box {
  border: 1px solid #e5e5e5; /* Add border */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); /* Add shadow */
}

/* You can adjust padding and background color as needed */
.user-info-box h4 {
  margin-bottom: 10px;
}

.user-info-box p {
  margin-bottom: 5px;
}

.user-info-box strong {
  font-weight: bold;
}
.nav-link {
  border-radius: 1rem 1rem 0 0 !important;
  background-color: #ffffff;
}
.nav-link.active {
  background-color: #967cf3 !important;
  color: #ffffff !important; /* Change text color to white */
}

/* Adjust the border radius of the active tab */
.nav-link.active {
  border-radius: 1rem 1rem 0 0 !important; /* Adjust as needed */
}
.user-detail-box {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}
