/* UserCard.css */

/* User card styling */
.user-content {
  margin: 20px;
}
.user-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

/* .user-manage-Container {
  margin-top: 20px;
} */
.user-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.user-card h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.user-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 10px;
  /* margin-right: 10px; */
}

/* Pagination styling */
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}

.pagination li {
  cursor: pointer;
  margin-right: 10px;
}

.pagination button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
}

.pagination button:hover {
  background-color: #0056b3;
}
.user-card {
  min-height: 234px;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
