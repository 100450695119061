.message-list {
  border-right: 1px solid #ddd;
  width: 28%;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.all-messages {
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

/* .message-list::-webkit-scrollbar {
  display: none; 
} */
.offer-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px; /* Optional: adds space between dot and text */
  background-color: #3300ff;
  margin: 2px;
}
.message-item {
  padding: 10px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  justify-content: space-between;
  border-bottom: 1px solid rgb(224, 222, 222);
  width: 300px;
}
.message-item.selected {
  background-color: #14a800; /* Background color for selected item */
  color: white;
  border-radius: 10px;
}

.message-item:hover {
  background-color: #e9e9e9;
  border-radius: 10px;
}

/* .message-item.unread {
    background-color: #f0f0f0;
  } */
.message-item.selected:hover {
  background-color: #14a800; /* Keep the selected background color on hover */
}

.avatar {
  margin-right: 10px;
}

.message-info {
  flex: 1;
}

.last-message {
  /* color: #888; */
  font-size: 12px;
  text-wrap: auto !important;
  width: auto !important;
  overflow-x: hidden;
}

/* .name {
  font-weight: bold;
} */
.unread-count {
  background-color: #ff0b0b;
  color: #fff;
  border-radius: 50%;
  padding: 0 5px;
  font-size: 10px;
  margin-left: auto;
  position: absolute;
  top: 33px; /* Adjust as necessary */
  right: 10px; /* Adjust as necessary */
}
.message-item.selected .message-date {
  color: #fff; /* Change the color of the date to white when the item is selected */
}
.message-date {
  color: #8c9292;
  font-size: 12px;
  position: absolute;
  top: 10px; /* Adjust as necessary */
  right: 10px; /* Adjust as necessary */
}
.chat-window {
  display: flex; /* Default for desktop */
  gap: 10px;
  min-height: 100%;
  width: 100%;
}
.wc-msg-filter-menu-item {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  border-bottom: 1px solid #ddd !important;
  cursor: pointer !important;
}
.chat-communication {
  width: 100%;
}
.chat-details {
  width: 30%;
  transition: transform 0.3s ease; /* Smooth transition for sliding effect */
  border-left: 1px solid #ddd; /* Divider line */
  padding: 10px;
  border-radius: 10px;
  overflow-y: scroll;
}
.chat-details::-webkit-scrollbar {
  width: 2px;
}
.chat-details.closed {
  transform: translateX(100%); /* Slide out of view */
}
.chat-details.open {
  transform: translateX(0); /* Slide into view */
}
.toggle-drawer-button {
  background-color: unset;
}
.close-details-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
.chat-header-container {
  border-bottom: 1px solid #ddd;
}
.chat-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;

  background-color: #fff;
}
.chat-title {
  display: flex;
  align-items: center;
}
.user-name {
  font-size: 18px;
  font-weight: bold;
  margin-right: 15px;
}
.user-action {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #777;
}
.user-action i {
  margin-right: 5px;
  font-size: 12px;
}
.action-icons {
  display: flex;
  gap: 15px;
}
.action-icons i {
  font-size: 16px;
  color: #28a745;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 8px;
  transition: background-color 0.3s, border-color 0.3s;
  cursor: pointer;
  display: flex;
}
.filter-icon {
  color: #28a745;
  transition: background-color 0.3s, border-color 0.3s;
  cursor: pointer;
  display: flex;
  filter: invert(42%) sepia(91%) saturate(3534%) hue-rotate(91deg)
    brightness(98%) contrast(106%);
}
.action-icons i:hover {
  background-color: #e9ecef;
  border-color: #28a745;
}
.chat-messages {
  flex: 1;
  padding: 10px;
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 79vh;
  min-height: 79vh;
}
.chat-messages::-webkit-scrollbar {
  display: none;
}
.message-status {
  position: absolute; /* Position relative to the parent container */
  bottom: 11px; /* Adjust distance from the bottom */
  right: 7px; /* Adjust distance from the right */
  font-size: 11px; /* Smaller font size for subtle appearance */
  color: #888; /* Muted color for less prominence */
}

.chat-message {
  margin-bottom: 10px;
}

.chat-message.own {
  text-align: right;
}
.chat-message.other {
  text-align: left;
}

.message-content {
  display: inline-block;
  padding: 10px;
  border-radius: 10px;
  background-color: #f0f0f0;
  max-width: 60%;
  min-width: 20%;
  text-align: left;
  word-break: break-all;
  white-space: pre-wrap;
  position: relative;
}

.chat-message.own .message-content {
  background-color: #dcf8c6;
}

.sender {
  /* font-weight: bold; */
  display: block;
}

.time {
  color: #888;
  font-size: 11px;
}

.message-container {
  display: flex;
  /* height: 90vh; */
  background-color: #fff;
}
.back-button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
  color: #007bff;
}

.back-button:hover {
  color: #0056b3;
}
/* Container for search input and icon */
.search-bar-container {
  position: relative; /* Position relative to contain the absolute positioned icons */
  width: 90%;
  margin: 5px auto; /* Center horizontally */
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: space-between;
}

/* Style for the search input */
.search-input {
  width: 100%;
  padding: 10px 40px 10px 40px; /* Left and right padding increased to accommodate both icons */
  border: 1px solid #ddd; /* Light border */
  border-radius: 20px; /* Rounded corners */
  font-size: 16px; /* Font size */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  outline: none; /* Remove default outline */
}

/* Search icon inside the input */
.search-icon {
  position: absolute; /* Position absolute within the container */
  left: 15px; /* Space from the left */
  color: #888; /* Icon color */
  font-size: 18px; /* Icon size */
}

/* Clear icon (X) inside the input */
.clear-icon {
  position: absolute; /* Position absolute within the container */
  right: 25px; /* Space from the right */
  color: #888; /* Icon color */
  font-size: 18px; /* Icon size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: color 0.3s ease; /* Smooth color transition */
}

.clear-icon:hover {
  color: #333; /* Darker color on hover */
}
.navbar-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
}
.dropdown {
  margin: 5px;
  flex: 1 1 auto;
}
@media (max-width: 1024px) {
  .chat-communication,
  .message-list,
  .chat-window {
    width: 100%;
  }
  .chat-messages {
    max-height: 82% !important;
    min-height: 82% !important;
  }
  .message-list {
    border-bottom: 1px solid #ddd;
  }
  .chat-details {
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    background: #fff;
    z-index: 1000;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  }
  .chat-header {
    min-height: 130px;
  }
  .chat-window.active {
    display: block;
  }
  .message-list.hidden {
    display: none;
  }
}

@media (max-width: 768px) {
  .navbar-container {
    flex-direction: column;
    align-items: stretch;
  }
  .chat-messages {
    max-height: 75% !important;
    min-height: 75% !important;
  }
  .chat-details {
    width: 100%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .dropdown {
    font-size: 0.9rem;
  }
  .chat-messages {
    max-height: 79% !important;
    min-height: 79% !important;
  }
  .chat-details {
    width: 70%;
  }
}

@media (min-width: 1025px) {
  .dropdown {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .chat-messages {
    max-height: 73% !important;
    min-height: 73% !important;
  }
}

@media (max-width: 320px) {
  .chat-messages {
    max-height: 70% !important;
    min-height: 70% !important;
  }
}

.upwork_message {
  height: calc(88vh - 200px); /* Adjust the value to fit your layout */
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box; /* Ensure padding/borders are included */
  padding: 10px; /* Optional */
  display: flex;
  flex-direction: column-reverse; /* Change from column-reverse to column */
}
.single-message,
.two-messages {
  margin-top: 0; /* No margin on top for 1 to 3 messages */
  height: 400px;
}

.upwork_message::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 768px) {
  .message-item {
    width: 380px !important;
  }
  .upwork_message {
    height: calc(88vh - 250px);
  }
}

@media screen and (max-width: 1024px) {
  .message-item {
    width: 100% !important;
  }
}

@media screen and (min-width: 1440px) {
  .message-item {
    width: 100% !important;
  }
}
