.plus-icon {
  display: flex;
  gap: 15px;
}
.plus-icon i {
  font-size: 16px;
  color: #28a745;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 8px;
  transition: background-color 0.3s, border-color 0.3s;
  cursor: pointer;
  display: flex;
}
.plus-icon i:hover {
  background-color: #e9ecef;
  border-color: #28a745;
}

.selectedMenu {
  background-color: #f0f0f0 !important;
}

.meeting-date-input {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 14px;
  background-color: #f0f0f0;
  color: #333;
}
.search-result-title {
  font-size: xx-small;
  color: #0000ff;
}
/* number input */
.guest-count-input {
  margin: 1px;
  text-align: center;
}
