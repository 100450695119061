.tv-screen-products-container {
  overflow: hidden;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.single-products-container {
  overflow: hidden;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  /* height: 100vh; */
}

.static-category-card {
  flex: 0 0 auto;
  min-width: 140px;
  max-width: 140px;
  min-height: 182px;
  max-height: 182px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(198, 198, 198);
  margin-right: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(36, 34, 34, 0.1);
  z-index: 99;
}
.tv-screen-carousel {
  display: flex;
  overflow: hidden;
  position: relative;
}
.tv-screen-carousel-inner {
  display: flex;
  animation: scroll 400s linear infinite reverse;
}
.single-carousel-inner {
  display: flex;
  animation: scroll 400s linear infinite reverse;
}
.special-effect {
  border: 2px solid #ffcc00; /* Glowing border */
  box-shadow: 0 0 20px rgba(255, 204, 0, 0.8);
}
.tv-card {
  /* flex: 0 0 auto; */
  min-width: 185px;
  max-width: 185px;
  margin-right: 10px;
  min-height: 182px;
  max-height: 182px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s ease-in-out;
}
.single-card {
  margin-right: 10px;
  width: 80vw;
  /* height: 80vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s ease-in-out;
}
.single-product-card {
  width: 100% !important;
  height: 90vh !important;
}
.single-product-image-container {
  width: 100%;
  height: 40%; /* Adjust to fit within card */
  display: flex;
  justify-content: center;
  align-items: center;
}
.single-card-container-guru {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Ensure full height of the card */
  padding: 10px; /* Add some padding for spacing */
}
.single-product-image {
  width: 90% !important;
  height: 100% !important;
  object-fit: cover;
  border-radius: 8px;
}
.single-guru-ext {
  flex-grow: 1; /* Allows it to fill remaining space in the container */
  display: flex;
  justify-content: space-between;
  align-items: center; /* Ensures vertical alignment */
  height: auto; /* Automatically adjusts based on content */
  padding: 10px; /* Optional: Add padding for aesthetics */
}

.single-qr-code {
  max-width: 80%;
  max-height: 80%;
}
h5.single-head {
  font-size: 30px !important;
  text-align: center !important;
  margin-bottom: 10px !important;
}

.single-dec {
  font-size: xx-large;
  color: #333;
  padding: 10px 20px;
  text-align: center;
}
.single-dial{
  font-size: 25px;
}
.tv-card.zoom {
  border: 2px solid #ffcc00;
  box-shadow: 0 0 20px rgba(255, 204, 0, 0.8), 0 0 20px rgba(255, 204, 0, 0.8),
    0 0 20px rgba(255, 204, 0, 0.8);
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.tv-screen-products-container::-webkit-scrollbar {
  display: none;
}

.product-image-container {
  overflow: hidden;
}

.product-image {
  width: 100%;
  height: 65px;
  object-fit: cover;
  border-radius: 8px;
}

.category-title {
  font-size: 12px;
  font-weight: 900;
  text-align: center;
  color: #000000;
}

.card-container-guru {
  height: 152px;
  padding: 5px;
}
.card-container-guru p {
  /* padding: 10px; */
  font-size: larger;
  font-weight: normal;
  cursor: pointer;
  max-height: 90px;
}

.head {
  width: auto;
  font-size: 12px !important;
}
.guru-ext {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
}
.guru-ext a {
  background-color: #f5f6ff;
  padding: 4px 3px;
  border-radius: 5px;
  font-size: 8px;
  width: auto;
}
/* Add this in your CSS file */
.flashing-text {
  /* animation: flash 1s infinite; */
  color: red; /* Ensure the text is red */
  display: flex;
  align-items: center;
  justify-content: center;
}
.flashing-text b {
  animation: 2s ease 0s infinite normal none running flashingRed;
  border-radius: 3px;
}

.dec {
  font-size: 8px !important;
  color: #000 !important;
  width: auto !important;
  height: 100px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  margin-bottom: 0px !important;
  text-align: center !important;
}
.qr-code {
  border-radius: 10px;
  margin-left: -16px;
}
/* Media queries for responsiveness */
@media (min-width: 1600px) {
  .static-category-card,
  .tv-card {
    width: 180px;
    height: 180px;
  }
}

@media (min-width: 2000px) {
  .static-category-card,
  .tv-card {
    width: 200px;
    height: 200px;
  }
}

@media (max-width: 1199px) {
  .static-category-card,
  .tv-card {
    width: 120px;
    height: 120px;
  }
}

@media (max-width: 767px) {
  .static-category-card,
  .tv-card {
    width: 100px;
    height: 100px;
  }
}
