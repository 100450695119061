.flashDeal-container {
  padding: 30px;
}
.flashDeal-container h5 {
  font-weight: bolder;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-decoration: underline;
  text-decoration-color: #616bff;
  cursor: pointer;
  text-align: center;
  padding: 50px;
}
.deals-form {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px;
  margin: 20px;
  box-shadow: 1px 1px 6px #888888;
}
.wc_dls-img {
  height: 100%;
  object-fit: cover;
  border-radius: 10px 0px 0px 10px;
  width: 100%;
}
.wc_deals-wrapper {
  border: 1px solid #dadada;
  border-radius: 10px;
}
.wc_dealcode {
  border: 1px dashed #302f31;
  padding: 5px;
  width: 120px;
  border-radius: 100px;
  text-align: center;
}

.wc_ib-tabs h5.active {
  background: #967cf3;
  color: #fff;
  text-align: center;
  padding: 14px;
  border-radius: 15px 15px 0px 0px;
}
.wc_ib-tabs h5 {
  background-color: #f7f7f7;
  text-align: center;
  padding: 14px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 15px 15px 0px 0px;
  border: 0.005rem solid #dadada;
  text-decoration: none;
}
