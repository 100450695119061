.need-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid lightgray;
  margin-top: 0px;
  width: 100%;
  padding: 0px;
}
.menu-container {
  position: relative;
}
.needs-image {
  width: 75%;
  max-width: 200px;
  height: auto;
  margin-right: 0;
}

.need-info {
  flex: 1;
  margin-top: 10px;
  padding: 10px;
}

.need-info .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.category-column {
  display: flex;
  align-items: center;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 40px;
}

.activate-button {
  background-color: #8de88d;
  color: #000;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

.edit-button {
  background-color: #002efa;
  color: #ffffff;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

.menu-toggle {
  font-size: 20px;
  cursor: pointer;
}

.menu {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 0;
  background: #fff;
  border: 1px solid #454545;
  /* padding: 10px; */
  border-radius: 5px;
  z-index: 99;
}

.need-footer {
  display: flex;
  margin-top: 10px;
  gap: 75px;
}

@media (min-width: 768px) {
  .need-card {
    flex-direction: row;
  }

  .needs-image {
    width: 30%;
    margin-right: 15px;
  }

  .need-info {
    margin-top: 0;
    padding: 0;
  }
}

@media (max-width: 767px) {
  .need-card {
    flex-direction: column;
  }

  .needs-image {
    width: 100%;
    max-width: 400px;
  }

  .need-info {
    margin-top: 10px;
  }

  .action-buttons {
    justify-content: space-between;
    margin-top: 10px;
  }
}
