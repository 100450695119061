/* @media only screen and (max-width:1024px){
  .lca-sidebar {
    display: none !important;
  }
} */
.side-bar {
  background-color: #ffffff; /* Sidebar background color */
  color: #000000; /* Sidebar text color */
  border-right: 1px solid rgb(224, 224, 224);
  padding: 5px 5px 0px 0px;
  height: 100%;
}
.sidebar-item {
  padding-bottom: 30px;
  cursor: pointer;
  border-radius: 5px;
}
/* Add this to your CSS file */
.sidebar-item.selected {
  background-color: #967cf3;
  color: #fff;
  border-radius: 0px 25px 0px 25px;
}

.sidebar {
  /* width: 25%; */
  background-color: #ffffff;
  padding: 20px;
  /* margin-left: -40px; */
  overflow-y: auto;
  height: 100vh;
  /* float: left; */
  border-right: "1px solid #c3c7ca";
}
.categoryList {
  padding: 2px;
  background-color: rgb(255, 255, 255);
  margin-bottom: 10px;
}
/* .categoryList:hover {
  border-radius: 5px;
  box-shadow: 0 0 30px rgba(228, 61, 61, 0.1); 
  transition: box-shadow 0.3s ease; 
} */
.categoryList h5 {
  font-weight: bold;
}
.price-slider {
  padding: 5px;
  background-color: #ffffff;
  margin-bottom: 10px;
}
/* .price-slider:hover {
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(228, 61, 61, 0.1); 
  transition: box-shadow 0.3s ease; 
} */

.radius-slider {
  padding: 5px;
  background-color: #ffffff;
  margin-bottom: 10px;
}
.radius-slider:hover {
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(228, 61, 61, 0.1); /* Add box shadow */
  transition: box-shadow 0.3s ease; /* Add a smooth transition for the box shadow */
}

.location-filter {
  padding: 5px;
  background-color: #ffffff;
  margin-bottom: 10px;
}
@media (min-width: 0px) and (max-width: 767px) {
  .mobile-only {
    display: block;
  }
}

@media (min-width: 768px) {
  .mobile-only {
    display: none;
  }
}
/* .location-filter:hover {
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(228, 61, 61, 0.1);
  transition: box-shadow 0.3s ease; 
} */
.filtered-locations {
  max-height: 100px; /* Set the maximum height for the list */
  overflow-y: auto;
}
.city-item {
  padding: 8px;
  /* border-bottom: 1px solid #ccc; */
  cursor: pointer;
}

.city-item:hover {
  background-color: #ffffff !important;
}
.sidebar h2 {
  margin-bottom: 20px;
  color: #007bff;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  cursor: pointer;
  font-size: 14px;
  /* margin-bottom: 2px; */
  /* padding: 8px; */
  border-radius: 5px;
  transition: background-color 0.1s;
}

.sidebar li:hover {
  background-color: #967cf3;
  color: #fff;
}
.sidebar .selected {
  /* color: blue; */
  font-weight: bold;
}
/* .selected-item {
  color: blue;
} */

.sidebar ul li {
  margin-left: 5px;
}

/* .sidebar ul li:hover {
  Leave this empty or define styles for subcategories if needed
} */

.content {
  /* margin-left: 270px; */
  /* padding: 20px; */
  background-color: #ffffff;
}
.local-community-ads-container::after {
  content: "";
  display: table;
  clear: both;
}
#mb-filter-btn {
  display: none;
}
@media only screen and (max-width: 1200px) {
  #lca-sidebar {
    display: none;
    position: fixed;
    right: -100%;
    background-color: #fff;
    z-index: 1000;
    transition: all 2s;
    top: 0px;
    transition-duration: 2s;
    width: calc(100% - 15px);
    border-radius: 5px 0 0 0;
    border-right: none;
    padding: 1rem 2rem;

    overflow-y: auto;
  }
  .tgl-close {
    display: none !important;
  }
  .tgl-show {
    display: block !important;
    right: 0px !important;
    /* height: auto !important; */
    width: 100% !important;
  }
  #mb-filter-btn {
    display: block;
  }
}
/* #lca-sidebar{
  overflow-x: visible;
} */
#lca-sidebar::-webkit-scrollbar {
  width: 5px;
}
/* li::marker {
  content: '+';
  font-size: 1.2em;
} */
.categoryList li {
  font-size: 14px;
  padding: 3px;
}
