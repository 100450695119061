/* ResetPwPage.css */
body {
  background-color: #f0f2f5;
}



.card {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title {
  color: #343a40;
}

.card-subtitle {
  font-size: 0.9rem;
}

.alert {
  margin-top: 15px;
}

button[disabled] {
  /* background-color: #007bff !important; */
  opacity: 0.65;
  cursor: not-allowed;
}
