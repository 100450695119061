.settings-nav {
  margin: 20px;
}
.react-datepicker-wrapper {
  width: 100%;
}
.horizontal-list {
  display: flex;
  gap: 40px; /* Adjust the gap between list items */
  flex-wrap: wrap;
}

.horizontal-list li {
  cursor: pointer;
  font-size: larger;
  color: #888888;
}

.horizontal-list li.selected {
  font-weight: bold; /* Add your selected item styles here */
  color: black;
}

.Profile-Container {
  background-color: #ffffff;
  border-radius: 10px;
  /* width: calc(100% - 60px);  */
  margin-top: 10px;
  padding: 20px;
  box-shadow: 1px 1px 6px #888888;
}
.message-box {
  background-color: #fff3cd;
  color: #856404;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
}
.prof-col {
  border-right: 1px solid black;
}
.profile-pic {
  padding: 10px;
  height: 150px;
  width: 150px;
}
.profile-pic img {
  width: 100%;
  height: 100%;
}
.insta-button {
  /* width: 55%; */
  /* margin-left: 10px; */
  background: linear-gradient(
    to right,
    #3d39ff,
    #9451ff
  ); /* Adjust colors as needed */
  border: none;
  color: white;
  /* padding: 10px; */
  cursor: pointer;
}

.insta-button:hover {
  background: linear-gradient(
    to right,
    #9451ff,
    #3d39ff
  ); /* Adjust colors as needed */
}

@media (max-width: 768px) {
  .Profile-Container {
    width: 100%; /* Change to 100% on smaller screens */
    margin-left: 0;
  }
  .form-container {
    padding: 20px; /* Adjust padding for smaller screens */
  }

  .hourly-offering {
    flex-direction: column; /* Stack columns for smaller screens */
    align-items: flex-start;
  }

  .highlighted-sample-work {
    padding: 20px; /* Adjust padding for smaller screens */
  }
  .hnav-list {
    gap: 20px;
  }
  .Profile-Container {
    padding: 15px;
  }
  .insta-button {
    width: 100%;
  }
  .Profile-Container {
    flex-direction: column;
  }
  .settings-nav {
    margin: 10px;
  }
  .input-width {
    width: 100% !important;
  }
}
@media (max-width: 508px) {
  .side-bar {
    /* display: -webkit-inline-box; */
    overflow-x: scroll;
    width: 100%;
    white-space: nowrap;
  }

  .side-bar::-webkit-scrollbar {
    display: none;
  }

  .sidebar-item {
    display: inline-block;
    padding: 10px;
    white-space: nowrap;
  }
  .stgs_sbar {
    border-bottom: 1px solid #998d8dda;
  }
  .side-bar {
    border: none;
  }
  .mb_ce-btns {
    padding-top: 25px;
  }
  .mb-add-service {
    font-size: 12px;
  }
}
.card {
  border-color: #afafaf;
}

select option {
  line-height: 30px !important;
}

.paymentHistory-image {
  position: absolute;
  top: 11.5%;
  right: 15%;
  height: 100px;
  width: 100px;
}

.cashOut-image {
  width: 100px;
  height: 100px;
  position: "absolute";
  top: 33%;
  left: 15%;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.blinking-circle.green {
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  animation: blink 1s infinite;
  margin-left: 5px; /* Adjust as needed for spacing */
}

/* Red indicator */
.blinking-circle.red {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  animation: blink 1s infinite;
  margin-left: 5px; /* Adjust as needed for spacing */
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.notify-me-content {
  transition: transform 0.3s ease-in-out;
}

.notify-item-enter {
  opacity: 1;
  /* transform: translateY(-20px); */
}

.notify-item-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

