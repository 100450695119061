.active-tab {
  color: #000000;
  background-color: #967cf3;
  border-radius: 0px 0px 0px 25px;
}
.btn-gotten {
  background: linear-gradient(
    45deg,
    #6a5acd 30%,
    #00bfff 90%
  ); /* Gradient background */
  color: white; /* Text color */
  border-radius: 25px; /* Rounded corners */
  padding: 10px 20px; /* Padding */
  transition: background 0.3s, transform 0.3s; /* Transition for hover effect */
}

.btn-gotten:hover {
  background: linear-gradient(
    45deg,
    #00bfff 30%,
    #6a5acd 90%
  ); /* Change gradient on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.home-after-login {
  /* height: 100vh; */
  overflow-y: scroll;
  overflow-x: hidden;
}
.side-bar {
  background-color: #ffffff; /* Sidebar background color */
  color: #000000; /* Sidebar text color */
  border-right: 1px solid rgb(224, 224, 224);
  padding: 5px 5px 0px 0px;
  height: 100%;
}
.sidebar-item {
  padding-bottom: 30px;
  cursor: pointer;
  border-radius: 5px;
}
.sidebar-item:hover {
  background-color: #967cf3;
  color: #fff;
  border-radius: 0px 0px 0px 25px;
}
/* Add this to your CSS file */
.sidebar-item.selected {
  background-color: #967cf3;
  color: #fff;
  border-radius: 0px 0px 0px 25px;
}

/* DashboardPage */

.card-container {
  padding: 10px;
}
.card-container p {
  /* padding: 10px; */
  font-size: larger;
  font-weight: normal;
  cursor: pointer;
}
/* .cards-grid {
  display: flex;
  gap: 30px;
} */
.cards-grid .Col {
  padding: 5px;
}
.pending-count {
  font-size: 20px;
  background-color: #ff0000;
  border-radius: 50%;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align children to the top */
  text-align: center;
  min-height: 170px;
  height: auto;
  overflow: auto;
}
.card-body p {
  font-size: 10px;
}
.profile-picture {
  margin-bottom: 10px;
  border-radius: 50%;
  overflow: hidden;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  background-color: #616bff;
}
.profile-picture img {
  width: 100%;
  height: 100%;
}

.card-title {
  /* position: absolute; */
  top: 10px;
  left: 10px;
  font-size: 12px;
  /* font-weight: bold; */
  opacity: 0.6;
}
.card-text {
  font-size: 15px;
}
.profile-picture > svg {
  width: 100%;
  height: 100%;
  color: #d6d5d5; /* Adjust the color as needed */
}

/* My services page */

/* .my-services-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 1px 2px 6px #888888;
}
.my-services-container h3 img p button {
  padding-bottom: 30px;
  background-color: aqua;
} */
/* .create-edit {
  overflow-y: scroll;
  height: 100vh;
} */
.form-container {
  background-color: #ffffff;
  border-radius: 10px;
  /* margin-left: 10px;
  margin-top: 20px; */
  padding: 10px;
  box-shadow: 1px 1px 6px #888888;
}
.add-service {
  /* background-color: #888888; */
  padding-top: 30px;
}
.add-service h1 {
  font-weight: bold;
}
.form-container h6 {
  font-weight: bold;
  height: auto;
  width: 100%;
}
.hourly-offering {
  border-radius: 10px;
  border: 1px solid rgb(235, 231, 231);
  justify-content: space-between;
  align-items: center;
}
.flat-flee-offerings {
  border-radius: 10px;
  border: 1px solid rgb(235, 231, 231);
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 10px;
  /* font-size: xx-large; */
  margin-bottom: 20px;
}
.free-gifts {
  border-radius: 10px;
  border: 1px solid rgb(235, 231, 231);
  margin-bottom: 20px;
  padding: 10px;
}
.highlighted-sample-work {
  border-radius: 10px;
  border: 1px solid rgb(235, 231, 231);
  padding: 20px;
}

.service-image-box {
  display: flex;
  width: 170px;
  height: 170px;
  justify-content: center;
  border: 1px solid rgb(235, 231, 231);
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .service-image-box {
    width: 135px;
    height: 135px;
  }
}

@media (max-width: 992px) {
  .service-image-box {
    width: 200px;
    height: 180px;
  }
}

@media (max-width: 768px) {
  .service-image-box {
    width: 200px;
    height: 180px;
  }
}

@media (max-width: 576px) {
  .service-image-box {
    width: 240px;
    height: 230px;
  }
}

@media (max-width: 400px) {
  .service-image-box {
    width: 240px;
    height: 225px;
  }
}

@media (max-width: 300px) {
  .service-image-box {
    width: 150px;
    height: 150px;
  }
}

/* .delete-icon {
  z-index: 9;
} */
.go-to-details {
  display: flex;
  padding: 5px;
  justify-content: space-between;
}
.featured {
  position: absolute;
  width: 100%;
  bottom: 0px;
  height: "16rem";
  overflow: "hidden";
  margin: "0";
  padding: "0";
  text-align: center;
  background-color: #e4942c;
}
/* MyNeedsOrRequests styles */
.requests-container {
  padding: 30px;
}
/* .requests-container .tab-content {
  margin-top: 50px;
} */
/* .requests-container .active-status {
  display: flex;
  column-gap: 70px;
} */
/* .requests-container .ads-container {
  padding: 20px;
} */
.invalid-date {
  border-color: red;
}

.ads-container h5 {
  font-weight: bolder;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  cursor: pointer;
}
.service-container {
  background-color: #ffffff;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 10px;
  border-radius: 15px;
  box-shadow: 1px 1px 6px #888888;
}
.service-container .service {
  background-color: #f4f1f1;
  width: 90%;
  flex-direction: column;
  /* padding: 10px; */
  margin: 20px 0px;
  border-radius: 2px;
  box-shadow: 0px 0px 10px #f5f5f5;
  border: 1px solid #dadada;
}
.service-container .service:hover {
  background-color: #ffffff;
  box-shadow: 1px 1px 6px #d1d1d1;
}
.service-container .service .ad-cat-edit {
  display: flex;
  justify-content: space-between;
}

.each-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 10px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 10px;
}
.ads-container .ads {
  background-color: #ffffff;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 30px 0px;
  border-radius: 15px;
  box-shadow: 1px 1px 6px #888888;
}
.ads-container .ads .card .img {
  height: 100px;
  width: 150px;
  object-fit: cover;
  border-radius: 8px;
}
.bwv-card-img {
  /* width: 20%; 
  height: auto; */
  max-height: 165px;
  border-top-left-radius: 8px; /* Round only the top-left corner */
  border-bottom-left-radius: 8px; /* Round only the bottom-left corner */
  object-fit: cover; /* Ensure the image covers the container */
}
.status {
  background-color: #f9f1dc;
  border-radius: 4px;
  padding: 4px 8px;
  margin-right: 10px;
}
.menu {
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 10px;
  width: 150px;
  right: 0;
  top: 20px;
  z-index: 9;
  color: black;
  text-align: center;
}
.menu p {
  color: #000000;
}
/* .menu p:hover {
  background-color: #ffffff;
  color: blue;
} */
.requests-container h5 {
  font-weight: bolder;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-decoration: underline;
  text-decoration-color: #616bff;
  cursor: pointer;
}
.requests-container h6 {
  font-weight: bolder;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  cursor: pointer;
}
.requests-container .ads {
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 50px;
  margin: 30px 10px;
  border-radius: 15px;
  box-shadow: 1px 1px 6px #888888;
}
/* .requests-container .ads img {
  width: 10rem;
  margin-bottom: 20px;
  object-fit: contain;
} */
.requests-container .ads h4 {
  margin-bottom: 20px;
}
/* .requests-container .ads p {
  margin-bottom: 20px;
} */

/* Add new ads */

.preview-ads {
  margin: 20px;
  border: 1px solid #d1cfcf;
  /* padding: 10px; */
  width: 90%;
}

/* orders styles */
.orders-container {
  margin: 20px;
}
.inbox-container {
  margin: 10px;
}
.inbox-container h5 {
  font-weight: bolder;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  cursor: pointer;
}

.inbox-container h5.active {
  text-decoration: underline; /* Apply underline for active tab */
  text-decoration-color: #616bff;
}

.orders {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 10px;
}
.orders .img {
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 8px;
}
.order-modal {
  width: 650px;
  margin: auto;
  background-color: #fff;
  padding: 50px;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
}
@media only screen and (max-width: 800px) {
  .order-modal {
    width: 100%;
    margin: auto;
    background-color: #fff;
    padding: 50px;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
  .order-modal h3,
  .order-modal p {
    text-align: center;
    padding-top: 10px;
  }
}
@media only screen and (max-width: 600px) {
  .Status {
    font-size: 10px;
  }
}

.message-card {
  width: 70%;
  /* max-width: 500px; Adjust as needed */
  margin: 10px;
  height: 50%; /* Adjust the height as needed */
  background-color: #616bff;
}
/* .message-card.message-card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #616bff;
} */
/* Add this CSS to your stylesheet */
.chat-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  background-color: #fff;
  height: 100%;
}

.message-box {
  display: inline-block;
  max-width: 70%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.message-box-left {
  background-color: #ffffff;
  align-self: flex-start;
}

.message-box-right {
  background-color: #dcf8c6;
  align-self: flex-end;
}

.message-content {
  word-wrap: break-word;
}
.request-quote {
  /* padding: 10px; */
  width: 300px;
  /* background-color: #f0efef; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1px;
}
.request-quote img {
  border-radius: 5px 0px 0px 5px;
}
.input-container {
  margin-top: auto; /* Push the input to the bottom */
  width: 100%;
  display: flex;
  gap: 5px;
}
.form-control,
.form-check-input,
.form-select {
  border-color: #858591;
  border-radius: 0.375rem !important;
}
#mobile {
  border-radius: 100px !important;
}
.css-1d6ozc4-control {
  border-radius: 100px;
  margin-right: 10px;
}
.os_btn {
  min-width: 160px;
}
@media only screen and (max-width: 600px) {
  .hire-modal {
    width: 96% !important;
    margin: auto;
  }
  .requests-container .ads {
    padding: 10px !important;
    margin: 20px 10px;
  }
  .requests-container {
    padding: 15px;
  }
  .needs-image {
    height: 150px;
    object-fit: contain;
  }
}

.wc_ib-tabs h5.active {
  background: #967cf3;
  color: #fff;
  text-align: center;
  padding: 14px;
  border-radius: 15px 15px 0px 0px;
}
.wc_ib-tabs h5 {
  background-color: #f7f7f7;
  text-align: center;
  padding: 15px 10px !important;
  font-size: 11px !important;
  font-weight: 500;
  border-radius: 15px 15px 0px 0px;
  border: 0.005rem solid #dadada;
  margin: auto;
}
@media only screen and (max-width: 600px) {
  .wc_add-timeslot {
    /* position: relative; */
    left: -158px;
    width: 200px;
    top: 37px;
  }
}

.left-arrow:hover,
.right-arrow:hover {
  background-color: #6c7473;
  color: #242727;
}

.notify-me-container {
  position: relative;
  margin-top: 40px; /* Adjust margin according to your layout */
}

.notify-me-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: 0;
  left: 0%;
  /* background-color: #f4f4f4; Light background color */
  padding: 45px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.notify-me-title {
  font-weight: bold;
  margin-bottom: 10px;
  color: #000000;
}

.notify-me-checkbox {
  margin-right: 10px;
}

.notify-me-button {
  align-self: flex-end;
}
.wc_fav-card {
  width: 32%;
}
@media only screen and (max-width: 500px) {
  .wc_fav-card {
    width: 100%;
  }
}

.myFavoutire {
  max-width: 32%;
}
@media only screen and (max-width: 500px) {
  .myFavoutire {
    max-width: 100%;
  }
}
