.carousel-item{
    /* border: none !important; */
    box-shadow: 0 0 0 white !important;
}
.slick-slider {
    height: 170px !important;
}

@media screen and (max-width: 575px) {
    .slick-slider {
        height: 320px !important;
    }
    .list_button {
        justify-content: space-evenly;
    }
    .list_text {
        padding: 0px 12px;
        margin-top: 10px;
    }
}
