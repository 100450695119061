.temp-image-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  cursor: pointer;
  margin: 5px auto;
  overflow: hidden;
  text-align: center;
  transition: all 0.4s ease;
  min-height: 110px;
  min-width: 110px;
  border: 1px solid #aaa;
}
.uploaded-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  transition: transform 0.3s ease;
}
.image-status {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}
.dragging {
  opacity: 0.8;
  transform: rotate(3deg) scale(1.05);
}
.preferred-title {
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: #ff9800;
  margin-bottom: 5px;
}

.image-status p {
  margin: 0;
  padding: 0;
}

/* Updated color styling applied to p tag */
.image-status.status-pending p {
  color: #cf9d06; /* Dark yellow text */
}

.image-status.status-approved p {
  color: #36904b; /* Dark green text */
}

.image-status.status-rejected p {
  color: #ff011b; /* Dark red text */
}

.image-status:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}
.temp-image-box[draggable="true"] {
  cursor: grab;
}

.temp-image-box:active {
  opacity: 0.6;
}
.highlighted {
  border: 3px solid gold; /* Example highlight effect */
  box-shadow: 0px 0px 10px rgba(255, 215, 0, 0.8);
  transform: scale(1.05); /* Slightly enlarge */
  transition: all 0.3s ease-in-out;
}
