.recent-ads-container {
  padding: 20px;
}

.lca-sidebar:hover {
  color: blue;
}

.horizontal-cards {
  display: flex;
  flex-wrap: wrap;
}
.results-container {
  display: flex;
  background-color: #fcefef;
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer;
  position: relative;
  align-items: center;
  gap: 8px;
}
.lca-clear-results {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
  color: red;
  cursor: pointer;
}
.lca-latest-ads-dropdown {
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
}
.lca-latest-dropdown {
  margin: 0;
  font-size: small !important;
  font-weight: bold !important;
  color: #000 !important;
  background-color: #fcefef !important;
  cursor: pointer !important;
  padding: 9px 18px !important;
  border-radius: 10px !important;
  border-color: white !important;
}
.lca-latest-dropdown-menu {
  border-radius: 10px;
  padding: 10px;
  z-index: 1;
  width: 215px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-left: -31%;
}
.lca-download-ads {
  background-color: #ddefd5;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: bold;
}
.lca-download-ads-mobile {
  background-color: #ddefd5;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: bold;
  justify-content: center;
  text-align: center;
}
.card {
  width: 100%;
  margin-bottom: 7px;
  border: 1px solid #ebe6e6;
  border-radius: 8px;
  display: flex; /* Use flex container to align items */
  flex-direction: row;
}

.card img {
  /* width: 20%; 
  height: auto; */
  max-height: auto;
  min-width: 50px;
  border-top-left-radius: 8px; /* Round only the top-left corner */
  border-bottom-left-radius: 8px; /* Round only the bottom-left corner */
  object-fit: cover; /* Ensure the image covers the container */
}

.card-content {
  padding: 10px;
  flex-grow: 1; /* Allow content to grow and take remaining space */
}

.card h5 {
  margin-top: 10px;
  color: blue;
  font-size: small;
  /* font-weight: bold; */
}
/* @media only screen and (min-width: 1025px) {
.wc_show_cat_popup{
  display: none;
}
}
@media only screen and (max-width: 1024px) {
  .wc_show_cat_popup{
    display: block !important;
  }
} */
/* Card Details page */

/* .first-Col {
  border-right: 1px solid #dbdbdb;
  padding-left: 50px;
}
.first-Col img {
  border-radius: 10px;
  width: 100px;
} */
.card-details-container {
  height: 100vh;
  /* overflow: scroll; */
  background-color: #ffffff;
}
/* .Card-Image {
  border-right: 1px solid #dbdbdb;
  display: flex;
  justify-content: center;
  align-items: flex-start;
} */
/* .Card-Image img {
  width: 25rem;
  border-radius: 10px;
} */
.card-details {
  padding: 25px;
}
.card-details-dec {
  padding: 25px;
}
.need-category-display {
  position: absolute;
  bottom: 0%;
  left: 11%;
  color: black;
  transform: translateX(-50%);
}
.row-cards {
  max-height: 100vh;
  /* overflow-y: scroll; */
}

/* Add this media query to target mobile view */
@media only screen and (max-width: 768px) {
  .need-category-display {
    left: 50%; /* adjust the left position to center the text */
    transform: translateX(-50%);
    width: 72%;
    bottom: 53%;
  }
}
@media only screen and (max-width: 600px) {
  .wd_filter-btn,
  .wd_filter-countaction {
    font-size: 9px;
  }
  /* .card img {
    width: 40%;
  } */
  .wc_egcpl-ad {
    width: 100% !important;
  }
}
.card-btn {
  font-size: 12px;
}
.card-btn:hover {
  background: none;
  border: 1px solid #0d6efd !important;
  color: #0d6efd;
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* CSS rules specific to Mac screen size 16 inches */
  #map {
    position: "absolute";
    bottom: "0%";
    left: "15%";
    width: "50px";
    padding-bottom: "13px";
  }
}

@media screen and (min-width: 1200px) {
  #map {
    position: "absolute";
    bottom: "0%";
    left: "15%";
    width: "50px";
    padding-bottom: "13px";
  }
}

/* Default for medium and large devices */
/* #filters {
  margin: 0;
  padding: 0;
} */
#finalProducts {
  margin: 0;
}

.mdFilter {
  font-size: 10px !important;
}

.view-tabs h5.active {
  background: #967cf3;
  color: #fff;
  text-align: center;
  padding: 14px;
  border-radius: 15px 15px 0px 0px;
}
.view-tabs h5 {
  background-color: #f7f7f7;
  text-align: center;
  padding: 14px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 15px 15px 0px 0px;
  border: 0.005rem solid #dadada;
  text-decoration: none;
}

/* For mobile devices (e.g., screens less than 768px wide) */
@media screen and (max-width: 767px) {
  #filters {
    margin-left: 86px;
  }
  /* #finalProducts {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scrollbar-width: none;
    -ms-overflow-style: none;
  } */
}
/* iPad in portrait mode (both old and new models) */
@media (min-width: 768px) {
  /* Styles for iPad in portrait mode */
  .subCatList {
    padding: 3px !important;
    font-weight: bold !important;
    font-size: 12px !important;
  }

  /* #filters{
    display: block !important;
  }
  #mb-filter-btn{
    display: none !important;
  }
  #wd_filter-btn{
    position: relative;
    top: -44px;
    display: block !important;
    right: 0%;
  }
  #wd_filter-parent-btn{
    display: none !important;
  } */
}
@media only screen and (max-width: 1200px) and (min-width: 768px) {
  #finalProducts {
    font-size: small !important;
    margin-left: 100px;
  }
}
@media only screen and (max-width: 1440px) and (min-width: 1024px) {
  #finalProducts {
    font-size: small !important;
  }
}
@media (min-width: 820px) {
  /* Styles for iPad in portrait mode */
  .subCatList {
    padding: 3px !important;
    font-weight: bold !important;
    font-size: 12px !important;
  }
  /* #filters{
    display: block !important;
  }
  #mb-filter-btn{
    display: none !important;
  } */
  /* #finalProducts{
    font-size: medium !important;
    margin-left: 100px ;
   } */
}
@media (min-width: 1024px) {
  /* Styles for iPad in portrait mode */
  .mdFilter {
    font-size: 10px !important;
  }
  .subCatList {
    display: inline-flex !important;
    margin-right: 12.9px;
  }
  .subCatList {
    font-size: 16px !important;
  }
}

@keyframes flashingRed {
  0% {
    box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px;
  }
  50% {
    /* background-color: rgba(230, 46, 46, 0.877); */
    box-shadow: rgb(250 12 12) 0px 1px 15px 1px;
  }
  100% {
    box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
