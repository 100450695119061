@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.inter-font {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
}
.categories__card img {
  width: 18px;
  height: 18px;
  object-fit: contain;
  opacity: 0.6 !important;
}
.categories__card span {
  margin: 10px 20px;
  text-transform: capitalize;
  font-size: 16px;
}
.categories__card .box {
  transition: 0.5s;
  padding: 0 20px;
  position: relative;
}
.categories__card .box:hover {
  /* background-color: #e0e0e0; */
  border: 1px solid rgb(194, 193, 193);
  cursor: pointer;
  color: #000000;
}
.categoryitemstyle {
  font-size: 14px;
  font-weight: 500;
  flex-basis: 98%;
  display: flex;
  align-items: center;
}

.categories__card {
  width: 100%;
  min-height: 560px;
  padding: 15px 0;
  border-radius: 0px;
  /* margin-top: 15px; */
  color: black;
  background: WHITE;
  position: relative;
  border: 1px solid #e3e2e2;
}
.categoryimg {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.color_item {
  background: red;
  height: 20px;
  border-radius: 100%;
  width: 20px;
}

.color_item:hover {
  border: 2px solid rgb(85, 116, 255);
  cursor: pointer;
}
.activecolor {
  border: 2px solid rgb(85, 116, 255);
}
.subcategory_list {
  position: absolute;
  left: 284px;
  background: white;
  top: -15px;
}
/* Add a CSS class to highlight the selected category */
.selected-category {
  background-color: #e0e0e0;
}
.menu {
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 12px;
  width: 100px;
  right: 0;
  top: 100%; /* Position the menu below the parent box */
  z-index: 9;
  color: black;
  text-align: center;
}
.menu p:hover{
  background-color: #0d6efd;
  color: #ffffff;
  gap: 10px;
  width: 100%;
  border-radius: 5px;
}

.all_cate_product {
 
  /* bottom: 20px;
  left: 60px;
  background: blue;
  font-size: 12px;
  padding: 5px 20px;
  color: var(--color-ffffff);
  border-radius: 5px;
  margin-right: 12px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06); */
  cursor: pointer;
}
.all_cate_product:hover {
  color: #0d6efd;
  background:none;
  border-color: #0d6efd;
}
.subcategories-list {
  padding-left: 55px; /* Add left padding to indent subcategories */
}

.subcategories-list li {
  list-style-type: none; /* Remove bullet points */
  margin: 5px 0; /* Adjust margin */
  font-size: 14px;
}

.subcategories-list li:hover {
  /* background-color: #5f5050; //Change background color on hover */
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .categories__card {
    width: 100%;
    min-height: 585px;
    padding: 15px 0;
    border-radius: 0px;
    margin-top: 15px;
    color: black;
    background: WHITE;
    border: 1px solid #e3e2e2;
  }
}
