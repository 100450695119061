.input-box {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
  align-items: center;
  width: 100%;
}
.input-box-icons {
  display: flex;
  gap: 7px;
  align-items: center;
  justify-content: center;
  font-size: larger;
  cursor: pointer;
  color: #757373;
}
.input-box-input {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #f9f9f9;
}

.input-box button {
  margin-left: 10px;

  cursor: pointer;
}

.input-box button:hover {
  background-color: #0056b3;
}
.input-box {
  position: relative;
}

.input-box-icons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.attach-options {
  position: absolute;
  bottom: 60px;
  left: 30px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100px;
  font-size: 14px;
  color: #333;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

.attach-options label {
  cursor: pointer;
  padding: 5px;
  color: #333;
  font-size: 14px;
  text-align: center;
}

.attach-options label:hover {
  background-color: #f0f0f0;
  border-radius: 3px;
}
